<div class="titulo_seccion">
    {{ 'usuarios.titulo' | translate }}
</div>
<p>
  usuarios works!
</p>
<table >
  <thead>
    <tr>
      <th class="text-center">'anuncios.label.type'</th>
      <th>'anuncios.label.name'</th>
      <th>'anuncios.table.description'</th>
      <th class="text-right"></th>
      <!--
      <th class="text-center">{{'anuncios.label.type' | translate}}</th>
      <th>{{'anuncios.label.name' | translate}}</th>
      <th>{{'anuncios.table.description' | translate}}</th>
      <th class="text-right"></th>
    -->
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of tableData1.dataRows" (click)="detalle()">
      <td class="text-center">{{row[0]}}</td>
      <td>{{row[1]}}</td>
      <td>{{row[2]}}</td>
      <td class="td-actions text-right">
        <button mat-raised-button type="button" class="btn btn-success {{row[3]}}">
          <i [id]="'edit' + row[0]" class="material-icons">edit</i>
        </button>
        <button mat-raised-button type="button" class="btn btn-danger {{row[3]}}">
          <i  [id]="'close' + row[0]" class="material-icons">close</i>
        </button>
        <button mat-raised-button type="button" class="btn btn-info {{row[3]}}">
          <i [id]="'group' + row[0]" class="material-icons">group</i>
        </button>
      </td>
    </tr>
  </tbody>
</table>