<div class="titulo_seccion">
    {{ 'administradores.titulo' | translate }}
</div>
<!--
<div class="titulo_seccion">
    {{ 'administradores.titulo' | translate }}
</div>
-->
<p>
  administradores works!
</p>

<!--
User name <input type="text" [(ngModel)]="userName" />
User name <input type="text" [(ngModel)]="userName" />
-->



<button (click)="search()">Search</button>

<div *ngIf="response">
respost
<hr />
{{ response.list }}


    <!--
    <tr ng-repeat="value in response2">
        <td>--x {{value}} </td> 
      </tr>

      <tr *ngFor="(key, value) in response.list">
      <td>-- {{key}} </td> <td>-- {{ value }} </td>
    </tr>
      -->
    <div *ngFor="let value of response.list">
      <!--
        <a [routerLink]="['admin/administradores', value.id_categoria]">Enlace</a>
        <a class="nav-link" href="#" [routerLink]="[item.link]"
        <a class="nav-link" href="#" [routerLink]="[item.link]">Enlace</a>

        <li *ngFor="let hero of heroes"
  [class.selected]="hero === selectedHero"
  (click)="onSelect(hero)">
  <span class="badge">{{hero.id}}</span> {{hero.name}}
</li>
    -->
      <a class="nav-link" href="#" [routerLink]="value.id_categoria">Enlace</a>
      <p>-- {{ value.id_categoria }} </p>
      <p>-- {{ value.categoria }} </p>
      <p>-- {{ value.color }} </p>
      <app-administrador-visualizacion [element]="value"></app-administrador-visualizacion>
    </div>

    <!--

      

      <div *ngFor="let value of response.list">
        <a [routerLink]="['/admin/administradores', value.id_categoria]">Enlace</a>
        <p>-- {{ value.id_categoria }} </p>
        <p>-- {{ value.categoria }} </p>
        <p>-- {{ value.color }} </p>
      </div>


    <div *ngFor="let value of response.list">
        <p>-- {{ value.id_categoria }} </p>
        <p>-- {{ value.categoria }} </p>
        <p>-- {{ value.color }} </p>
      </div>

    -->

    <div *ngFor="let value of response.list">
      <p>-- {{ value.id_categoria }} </p>
      <p>-- {{ value.categoria }} </p>
      <p>-- {{ value.color }} </p>
    </div>


    

</div>

<p>Respuesta: {{ response?.list }}</p>
<p>Respuesta: {{ response?.list[0] }}</p>
<p>Respuesta: {{ response?.list[0].categoria }}</p>
<p>Respuesta: {{ response?.list[0].color }}</p>
<p>Respuesta: {{ response?.list[0].id_categoria }}</p>

Categoria <input type="text" [(ngModel)]="categoria" />
<button (click)="guardar()">Guardar</button>
<!--
Categoria <input type="text" [(ngModel)]="categoria" />
<button (click)="guardar()">Guardar</button>

Categoria <input matInput type="text" [(ngModel)]="categoria" [ngModelOptions]="{standalone: true}" />
Categoria <input matInput type="text" [(ngModel)]="categoria" />

Categorias <input matInput type="text" [(ngModel)]="categoria" >

Categorias <input matInput type='text' [(ngModel)]='categoria' name="categoria">
-->

<!--
Categoria
{{ categoria }}
-->

<!--
Categoria <input type="text" [(ngModel)]="categoria" />
<button (click)="guardar()">Search</button>
-->
<!--
<div ng-repeat="(key, value) in response?.list">
    {{key}}
    {{value}}  
    <hr />
</div>
-->


<!--
<div ng-repeat="(key, value) in response.list">

  
</div>
-->
<!--
<table mat-table [dataSource]="myDataArray">
    
</table>
-->
<!--

<mat-table [dataSource]="dataSource">
    User name Definition
    <ng-container cdkColumnDef="username">
      <mat-header-cell *cdkHeaderCellDef> User name </mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{row.username}} </mat-cell>
    </ng-container>
  
    Age Definition
    <ng-container cdkColumnDef="age">
      <mat-header-cell *cdkHeaderCellDef> Age </mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{row.age}} </mat-cell>
    </ng-container>
  
    Title Definition
    <ng-container cdkColumnDef="title">
      <mat-header-cell *cdkHeaderCellDef> Title </mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{row.title}} </mat-cell>
    </ng-container>
  
    Header and Row Declarations
    <mat-header-row *cdkHeaderRowDef="['username', 'age', 'title']"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: ['username', 'age', 'title']"></mat-row>
  </mat-table>
-->

