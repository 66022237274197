<div class="titulo_seccion">
    {{ 'servicios.titulo' | translate }}
</div>
<p>
  servicios works!
</p>
<!--
User name <input type="text" [(ngModel)]="userName" />
<button>Search</button>
-->

<h1>--- {{ 'principal.texto' | translate }}</h1>
    <h1>--- {{ 'principal.texto2' | translate }}</h1>
    <h1>--- {{ 'principal.texto3' | translate }}</h1>

User name <input type="text" [(ngModel)]="userName" />
<button (click)="search()">Search</button>

<div *ngIf="response">

<p>Login name: {{ response.login }}</p>
<p>Number of repos: {{ response.public_repos }}</p>
<p>Number of gists: {{ response.public_gists }}</p>
<p>Number of followers: name {{ response.followers }}</p>

</div>


<p>2Login name: {{ response?.login }}</p>
<p>2Number of repos: {{ response?.public_repos }}</p>
<p>2Number of gists: {{ response?.public_gists }}</p>
<p>2Number of followers: name {{ response?.followers }}</p>

<!--
  LAZY LOADING
<router-outlet></router-outlet>
-->
