<p>
  translate works!!!!
</p>
<div class="contenedor">

  <!--
    <h1>--- {{ 'principal.texto' | translate }}</h1>
    <h1>--- {{ 'principal.texto2' | translate }}</h1>
    <h1>--- {{ 'principal.texto3' | translate }}</h1>
-->
<!--
<h1>--- {{ 'principal.texto' | translate }}</h1>
<h1>--- {{ 'principal.texto2' | translate }}</h1>
<h1>--- {{ 'principal.texto3' | translate }}</h1>
-->
    <div class="contenedor-botones">

      <!--Consumiendo desde el servicio
        <button [ngClass]="{'active': activeLang == 'es'}" (click)="_data.cambiarLeng('es')"><img src="https://aguitech.com/images/utiles/flags/mex.png" class="flag_lang" />{{ 'lang.spanish' | translate }}</button>
      <button [ngClass]="{'active': activeLang == 'en'}" (click)="_data.cambiarLeng('en')"><img src="https://aguitech.com/images/utiles/flags/usa.png" class="flag_lang" />{{ 'lang.english' | translate }}</button>
      <button [ngClass]="{'active': activeLang == 'jp'}" (click)="_data.cambiarLeng('jp')"><img src="https://aguitech.com/images/utiles/flags/jpn.png" class="flag_lang" />{{ 'lang.japanese' | translate }}</button>
      
      
    -->
    <button [ngClass]="{'active': activeLang == 'es'}" (click)="cambiarLenguaje('es')"><img src="https://aguitech.com/images/utiles/flags/mex.png" class="flag_lang" />{{ 'lang.spanish' | translate }}</button>
    <button [ngClass]="{'active': activeLang == 'en'}" (click)="cambiarLenguaje('en')"><img src="https://aguitech.com/images/utiles/flags/usa.png" class="flag_lang" />{{ 'lang.english' | translate }}</button>
    <button [ngClass]="{'active': activeLang == 'jp'}" (click)="cambiarLenguaje('jp')"><img src="https://aguitech.com/images/utiles/flags/jpn.png" class="flag_lang" />{{ 'lang.japanese' | translate }}</button>
      <!--Consumiendo desde el componente
      <button [ngClass]="{'active': activeLang == 'jp'}" (click)="cambiarLenguaje('jp')"><img src="https://aguitech.com/images/utiles/flags/jpn.png" class="flag_lang" />{{ 'lang.japanese' | translate }}</button>
    -->
    </div>
  </div>