<div class="titulo_seccion">
    {{ 'equipo.titulo' | translate }}
</div>
<p>
  equipo works!
</p>
<div>
    
      
    
    <h2> Currency Rates. </h2>
    <h3> From Euro to the world </h3>
    <pre>{{ currentEuroRates | json }}</pre>

</div>
<app-main-table [dataTable]="currentEuroRates"></app-main-table>